import { ControllerFlowAPI } from 'yoshi-flow-editor-runtime/build/cjs/flow-api/ViewerScript';
import { IWixAPI, IAppData } from '@wix/native-components-infra/dist/src/types/types';
import { PublicPlan } from '@wix/ambassador-pricing-plans-read-api/http';

import { TabState, CheckoutData } from '../../types/common';
import { PricingPlansApi } from '../../services/pricing-plans';
import { encodeBase64Url, decodeBase64Url } from '../../services/encode-base64/encode-base64';
import { plansFixture } from '../../fixtures';
import { ListProps } from '../PackagePicker/Widget/List';

export class PlanListController {
  constructor(
    public setProps: (props: Partial<ListProps>) => void,
    protected api: PricingPlansApi,
    protected flowAPI: ControllerFlowAPI,
    protected wixCodeApi: IWixAPI,
    protected appParams: IAppData,
  ) {}

  public async initializeProps() {
    const plans = await this.api.loadPaidPlans({ planIds: [] });
    const useFixture = !plans?.length && this.wixCodeApi.window.viewMode === 'Editor';

    this.setProps({
      plans: useFixture ? plansFixture : plans,
      tabState: TabState.REGULAR,
      navigateToCheckout: this.navigateToCheckout,
      hidePopup: this.hidePopup,
      popup: null,
      isMobile: this.flowAPI.isMobile(),
    });

    this.flowAPI.biLogger?.plansPageView({ widgetType: 'list' });
  }

  private navigateToCheckout = async (plan: PublicPlan) => {
    this.flowAPI.biLogger?.planPurchaseClick({ planGuid: plan.id, widgetType: 'list' });
    const checkoutData: CheckoutData = { planId: plan.id!, integrationData: {} };
    this.setCurrentPath('/payment/' + encodeBase64Url(checkoutData));
  };

  private hidePopup = () => this.setProps({ popup: null });

  private setCurrentPath = async (path: string) => {
    const { relativeUrl } = await this.wixCodeApi.site.getSectionUrl({
      appDefinitionId: this.appParams.appDefinitionId,
      sectionId: 'membership_plan_picker_tpa',
    });
    this.wixCodeApi.location.to!((relativeUrl ?? '') + path);
  };
}
